:root{
  --primary: #294e95;
  --priweak: #294e95a1;
  --secondary: #009bdc;
  --info: #3498DB;
  --good: #2ECC71;
  --bad: #E74C3C;
  --warning: #F2AD00;
}

/* Hinterlegen von weiteren CSS Klassen */

/* Kopfzeile der App */
.header-content strong{
  color: #fff;
}
.header-content span{
  color: #fff;
}

#id1 {
  background: rgb(205,61,54) !important;
  color: #ffffff !important;
  border: 3px solid #cd3d36;
  border-radius: 5px;
  margin: 0 0 -3px 0;
}
#id2 {
  background: rgb(242,206,40)!important;
  color: #ffffff !important;
  border: 3px solid #F2CE28FF;
  border-radius: 5px;
  margin: 0 0 -3px 0;
}
#id3 {
  background: rgb(114,165,69)!important;
  color: #ffffff !important;
  border: 3px solid #72A545FF;
  border-radius: 5px;
  margin: 0 0 -3px 0;
}
#id4 {
  color: #ffffff !important;
  background-color: rgb(224, 152, 48) !important;
  border: 3px solid #E09830FF;
  border-radius: 5px;
  margin: 0 0 -3px 0;
}
#id5 {
  background: rgb(185, 186, 191) !important;
  color: #ffffff !important;
  border: 3px solid #B9BABFFF;
  border-radius: 5px;
  margin: 0 0 -3px 0;
}
#id6 {
  background: rgb(0,138,196)!important;
  color: #ffffff !important;
  border: 3px solid #008AC4FF;
  border-radius: 5px;
  margin: 0 0 -3px 0;
}
#id7,
#id8,
#id9,
#id10 {
  background-color: rgb(27, 97, 133) !important;
  color: #ffffff !important;
  border: 3px solid #1B6185FF;
  border-radius: 5px;
  margin: 0 0 -3px 0;
}
